//      
import FindingTargetStatusEnum from '@dt/enums/FindingTargetStatusEnum';
import { flatMap, flow, filter, map } from 'lodash/fp';
import { max, differenceInDays } from 'date-fns';

export default function(finding                 )         {
  const dateOfLastOpen = flow(
    flatMap(({ statuses }) => statuses),
    filter(({ status }) =>
      [FindingTargetStatusEnum.OPEN, FindingTargetStatusEnum.NEW].includes(
        status,
      ),
    ),
    map(({ date }) => date),
    dates => max(...dates),
  )(finding.targets);

  return differenceInDays(new Date(), dateOfLastOpen); // UTC?
}
