//      
import React from 'react';
import { Link } from '@reach/router';
import { Card, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import ComplianceTagStrip from '@dt/material-components/compliance-tag/ComplianceTagStrip';
import { affectedComponentDescription } from './util';
import { RelevanceTag, PolicyViolationLabel } from '@dt/material-components';
import { dateFormats } from '../../util/dateFormats'
import { formatDateDefault } from '../../util/formatDateDefault'
const breakpoint = 1100;

const styles = () => {
  return {
    row: {
      margin: '8px 0',
      display: 'flex',
      alignItems: 'center',
      [`@media (max-width: ${breakpoint - 1}px)`]: {
        flexWrap: 'wrap',
      },
    },
    rowLeft: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 8,
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      [`@media (max-width: ${breakpoint - 1}px)`]: {
        justifySelf: 'flex-start',
        flexWrap: 'wrap',
      },
    },
    rowRight: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      [`@media (min-width: ${breakpoint}px)`]: {
        marginLeft: 'auto',
        justifyContent: 'flex-end',
      },
      [`@media (max-width: ${breakpoint - 1}px)`]: {
        marginTop: 8,
      },
    },
    violationTitle: {
      // 36 is the width of the Relevance Tag
      [`@media (max-width: ${breakpoint - 35}px)`]: {
        marginTop: 16,
        marginBottom: 8,
      },
    },
    rowItem: {
      marginRight: 8,
    },
    reflowContent: {
      margin: '0 8px',
      [`@media (min-width: ${breakpoint}px)`]: {
        display: 'none',
      },
    },
    reflowHide: {
      [`@media (max-width: ${breakpoint - 1}px)`]: {
        display: 'none',
      },
    },
  };
};

function PolicyViolationCard({ policy_violation, productBasePath, classes, isV2 }       ) {
  return (
    <Link
      to={
        isV2
          ? `${productBasePath}/v2/policy-violations/${policy_violation.id}`
          : `${productBasePath}/policy-violations/${policy_violation.id}`
      }
      style={{ display: 'block' }}
    >
      <Card style={{ padding: 16, width: '100%' }}>
        <div className={classes.row}>
          <div className={classes.rowLeft}>
            <div className={classes.rowItem}>
              <RelevanceTag relevance={policy_violation.violated_policy_rule.relevance} size="medium" />
            </div>
            <Typography variant="body1" component="div" className={classes.violationTitle}>
              {policy_violation.violated_policy_rule.policy_rule_type.title}
            </Typography>
          </div>

          <div className={classes.rowRight}>
            <Typography variant="body2" component="div" className={classes.rowItem}>
              {`ID: ${policy_violation.id.substr(0, 8)}`}
            </Typography>

            <PolicyViolationLabel status={policy_violation.status} />

            <div className={classes.reflowContent}>
              <Typography variant="body2" component="div" className={classes.rowItem}>
                {policy_violation.date_resolved
                  ? `Resolved ${formatDateDefault({ date: policy_violation.date_resolved, formatStr: dateFormats.MMMddyyyy} )}`
                  : policy_violation.exception_date_created
                  ? `Closed ${formatDateDefault({ date: policy_violation.date_created, formatStr: dateFormats.MMMddyyyy} )}`
                  : `Caught ${formatDateDefault({ date: policy_violation.date_created, formatStr: dateFormats.MMMddyyyy} )}`}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <Typography variant="body2" component="div" className={classes.rowLeft}>
            {policy_violation.affected_asset_name && policy_violation.affected_asset_type_name
              ? `${policy_violation.affected_asset_type_name} at ${policy_violation.affected_asset_name}`
              : affectedComponentDescription(policy_violation)}
          </Typography>

          <div className={classes.rowRight}>
            <Typography variant="body2" component="div" className={classes.reflowHide}>
              {policy_violation.date_resolved
                ? `Resolved ${formatDateDefault({ date: policy_violation.date_resolved, formatStr: dateFormats.MMMddyyyy} )}`
                : policy_violation.exception_date_created
                ? `Closed ${formatDateDefault({date: policy_violation.date_created, formatStr: dateFormats.MMMddyyyy} )}`
                : `Caught ${formatDateDefault({date: policy_violation.date_created, formatStr: dateFormats.MMMddyyyy} )}`}
            </Typography>
          </div>
        </div>

        <div className={classes.row}>
          <ComplianceTagStrip
            granular
            complianceReferences={policy_violation.violated_policy_rule.policy_rule_type.compliance_policy_references.map(
              ({ ...other }) => other,
            )}
          />
        </div>
      </Card>
    </Link>
  );
}

export default React.memo(withStyles(styles)(PolicyViolationCard));
