var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Box } from '@mui/material';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { Text as DTText } from '@dt/material-components';
import { truncateNumber } from '@utils/truncateNumber';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import { Text } from '@visx/text';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import AnimatedPie from '../AnimatedPie';
var tooltipStyles = __assign(__assign({}, defaultStyles), { minWidth: 60 });
var defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };
var PieChart = function (_a) {
    var shouldHideTooltip = _a.shouldHideTooltip, width = _a.width, height = _a.height, margin = _a.margin, data = _a.data, text = _a.text, subText = _a.subText, _b = _a.smallText, smallText = _b === void 0 ? false : _b, textWidth = _a.textWidth, _c = _a.isEmptyState, isEmptyState = _c === void 0 ? false : _c;
    var _d = useState(null), activeArc = _d[0], setActiveArc = _d[1];
    var _e = useTooltip(), tooltipOpen = _e.tooltipOpen, tooltipLeft = _e.tooltipLeft, _f = _e.tooltipTop, tooltipTop = _f === void 0 ? 0 : _f, tooltipData = _e.tooltipData, hideTooltip = _e.hideTooltip, showTooltip = _e.showTooltip;
    var _g = useTooltipInPortal({
        scroll: true,
    }), containerRef = _g.containerRef, TooltipInPortal = _g.TooltipInPortal;
    var pieMargin = margin || defaultMargin;
    var innerWidth = width - pieMargin.left - pieMargin.right;
    var innerHeight = height - pieMargin.top - pieMargin.bottom;
    var isSmall = innerWidth < 75;
    var radius = Math.min(innerWidth, innerHeight) / 2;
    var centerY = innerHeight / 2;
    var centerX = innerWidth / 2;
    var donutThickness = isSmall ? 8 : 15;
    var usage = function (d) { return d.value; };
    var handleTooltip = function (arc) { return function (event) {
        if (isEmptyState)
            return;
        var x = (localPoint(event) || { x: 0 }).x;
        var y = (localPoint(event) || { y: 0 }).y;
        showTooltip({
            tooltipData: arc,
            tooltipLeft: x,
            tooltipTop: y,
        });
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { width: width, height: height, ref: containerRef },
            React.createElement(Group, { top: centerY + pieMargin.top, left: centerX + pieMargin.left },
                React.createElement(Pie, { data: data, pieValue: usage, outerRadius: function (data) {
                        var updatedRadius = radius;
                        if (data.data.name === (activeArc === null || activeArc === void 0 ? void 0 : activeArc.name)) {
                            return updatedRadius + (isSmall ? 1 : 3);
                        }
                        return updatedRadius;
                    }, innerRadius: function (data) {
                        var updatedRadius = radius - donutThickness;
                        if (data.data.name === (activeArc === null || activeArc === void 0 ? void 0 : activeArc.name)) {
                            return updatedRadius - (isSmall ? 1 : 3);
                        }
                        return updatedRadius;
                    }, cornerRadius: 3, padAngle: 0.01 }, function (pie) { return (React.createElement(AnimatedPie, __assign({}, pie, { setActiveArc: setActiveArc, onClickDatum: function () { }, getKey: function (arc) { return arc.data.name; }, getColor: function (d) { return d.data.color; }, onTouchStart: handleTooltip, onTouchMove: handleTooltip, onMouseMove: handleTooltip, onMouseLeave: function () { return function () {
                        hideTooltip();
                    }; } }))); }),
                React.createElement(Text, { textAnchor: "middle", verticalAnchor: "middle", fontSize: 20, dy: -10, className: smallText ? 'text-helperText' : 'text-titleL', width: textWidth }, text),
                React.createElement(Text, { textAnchor: "middle", verticalAnchor: "middle", fontSize: 20, dy: text ? 10 : 0, className: "text-helperText", width: textWidth }, subText))),
        !shouldHideTooltip && tooltipOpen && tooltipData && (React.createElement(TooltipInPortal, { top: tooltipTop, left: tooltipLeft, style: tooltipStyles },
            React.createElement(Box, { display: "flex", alignItems: "center" },
                React.createElement(Box, { style: {
                        width: 10,
                        height: 10,
                        backgroundColor: tooltipData.color,
                        display: 'inline-block',
                        marginRight: 5,
                    } }),
                React.createElement(DTText, { variant: "body", component: "div" },
                    truncateNumber(tooltipData.value),
                    " ",
                    tooltipData.name))))));
};
export default PieChart;
