//      
import React, { memo } from 'react';
import Banner from './Banner';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { palette } from '@dt/theme';
import { dateFormats } from '../../apps/ahura/src/util/dateFormats'
import { formatDateDefault } from '../../apps/ahura/src/util/formatDateDefault';

export default memo       (function OfflineIndicator({ assetTypeName, url, lastUpdated }) {
  return (
    <Banner status="critical" inline icon={<NotificationImportantIcon />} iconColor={palette.red30}>
      {`This ${assetTypeName} was unreachable ${url ? `at ${url}` : ''} ${
        lastUpdated ? `on ${formatDateDefault({date: lastUpdated, formatStr: dateFormats.MMMddyyyy})}` : ''
      }. It may have been removed from the environment.`}
    </Banner>
  );
});
