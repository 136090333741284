//      
import React, { memo } from 'react';
import { Text } from '@dt/material-components';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import { dateFormats } from '../../util/dateFormats'
import { formatDateDefault } from '../../util/formatDateDefault';      

const InventoryTableCellDiscoveryDateComponent = function InventoryTableCellDiscoveryDate(props) {
  if (props.loading) {
    return (
      <TableCell component="th" align={'left'}>
        <Box display={'flex'}>
          <Skeleton animation="wave" variant="text" height={28} width={80} />
        </Box>
      </TableCell>
    );
  }

  const { date_created, align } = props;

  return (
    <TableCell component="th" align={align || 'center'}>
      <Text component={'div'} variant={'body'}>
        {formatDateDefault({date: date_created, formatStr: dateFormats.MMddyyyy})}
      </Text>
    </TableCell>
  );
};

export default memo(InventoryTableCellDiscoveryDateComponent);
