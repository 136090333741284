//      
import { useEffect } from 'react';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import assets from '@dt/graphql-support/horizon/assets';
import { dateFormats } from '../../../util/dateFormats'
import { formatDateDefault } from '../../../util/formatDateDefault';
                                                                                         

function convertToCSV(objArray) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line != '') line += ',';

      line += array[i][index];
    }

    str += line + '\r\n';
  }

  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFileName = fileTitle + '.csv' || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  // IE 10+
  // $FlowFixMe -- Flow is missing this in Navigator
  if (navigator.msSaveBlob) {
    // $FlowFixMe -- Flow is missing this in Navigator
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body?.appendChild(link);
      link.click();
      document.body?.removeChild(link);
    }
  }
}

export default function useExportAssets   (filters    ) {
  const [fetchAssets, { data, fetchMore, loading, error }] = useLazyQuery(
    assets.list,
  );

  const caller = () => {
    fetchAssets({
      variables: {
        ...filters,
        page_size: 250,
      },
    });
  };

  // Fetch all assets.
  useEffect(() => {
    if (fetchMore) {
      fetchMore && fetchMore();
    }
  }, [fetchMore]);

  useEffect(() => {
    if (!fetchMore && data) {
      exportCSVFile(
        {
          asset_type: 'Asset Type'.replace(/,/g, ''), // remove commas to avoid errors
          name: 'Asset Name'.replace(/,/g, ''),
          url: 'Asset Url'.replace(/,/g, ''),
          open_important_policy_violations_count: 'Open Important Policy Violations Count'.replace(/,/g, ''),
          open_proactive_policy_violations_count: 'Open Proactive Policy Violations Count'.replace(/,/g, ''),
          open_urgent_policy_violations_count: 'Open Urgent Policy Violations Count'.replace(/,/g, ''),
          hosted_on: 'Hosted On'.replace(/,/g, ''),
          discovered_via: 'Discovered Via'.replace(/,/g, ''),
          status: 'Status',
          date_created: 'Discovery Date'.replace(/,/g, ''),
        },
        data.asset_list.assets.map(asset => ({
          asset_type: asset.asset_type_name.replace(/,/g, ''),
          name: asset.name.replace(/,/g, ''),
          url: (asset.url || ' ').replace(/,/g, ''),
          open_important_policy_violations_count: asset.open_important_policy_violations_count,
          open_proactive_policy_violations_count: asset.open_proactive_policy_violations_count,
          open_urgent_policy_violations_count: asset.open_urgent_policy_violations_count,
          hosted_on: (asset.hosted_on || ' ').replace(/,/g, '') || '',
          discovered_via: asset.discovered_via.replace(/,/g, ''),
          status: asset.status.replace(/,/g, ''),
          date_created: formatDateDefault({date: asset.date_created, formatStr: dateFormats.MMddyyyy}),
        })),
        `data_theorem_assets_${formatDateDefault({date: Date.now(), formatStr: dateFormats.MMddyyyy})}_${Object.values({
          ...filters,
        })
          .join('_')
          .replace(',', '_')
          .toLowerCase()}`,
      );
    }
  }, [fetchMore, data, filters]);

  return {
    caller,
    completedCount: data?.asset_list.assets.length || 0,
    totalCount: data?.asset_list.pagination_information.total_count || 0,
    loading,
    error,
  };
}
